import { Button, Divider, Drawer, IconButton, Link, List, ListItem, ListItemText, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import { Component } from "react";
import { Discord } from "./Icons/Discord";
import { Instagram } from "./Icons/Instagram";
import { Logo } from "./Logo";
import { style, theme } from "./Theme";

interface Props {}
interface State {
  open: boolean;
}
export class Sitebar extends Component<Props, State> {

  public state: State = {
    open: false
  }

  public render() {
    return (
      <div>
        <Box style={{ margin: "0 auto", maxWidth: style.page.maxWidth }}>
          <Toolbar style={{height:112, marginTop:0}} >
            {this.drawer()}
            <Logo/>
            <Typography variant="h5" color="inherit" style={{fontWeight:500}} sx={{ flexGrow: 1 }}>Balloondog</Typography>
            <Box sx={{ display: { xs: 'none', md:'block' } }}>
                {this.links()}
            </Box>
          </Toolbar>
        </Box>
        <Divider />
      </div>
    );
  }

  private drawer(){
    return (
      <>
        <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={()=>this.toggleDrawer(true)}
            sx={{
              mr: 2,
              display: {
                sm: "block",
                md: "none"
              }
            }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer     
          anchor="left"
          variant="temporary"
          open={this.state.open}
          onClose={()=>this.toggleDrawer(false)}
        >           
            <Box>
              {this.listLinks()}
            </Box>
        </Drawer>
      </>

    )
  }

  private subBar() {
    return (
      <Box >
        <Toolbar style={{height:32, marginTop:-12}}>
        <Box display='flex' flexGrow={1}></Box>
          <IconButton edge="end" color="inherit" aria-label="menu">
            <Link href="https://instagram.com/balloondogcaps" underline="none">
              <Instagram />
            </Link>
            
          </IconButton>
          <IconButton edge="end" color="inherit" aria-label="menu">
          <Link href="https://discord.gg/bqN9rMdSXV" underline="none">
              <Discord />
            </Link>
          </IconButton>
        </Toolbar>
      </Box>
    );
  }

  private links(){
    const weight = {
      fontWeight: 600
    }
    const style = {
      color:"black",
      //...weight
    }
    return (
      <>
          <Button style={{marginLeft:8}} color="inherit"><Link style={style} href="https://dutchtypeworks.atlassian.net/servicedesk" underline="none">Create Ticket</Link></Button>
          <Button style={{marginLeft:8}} color="inherit"><Link style={style} href="https://github.com/balloondogcaps" underline="none">Build Guides</Link></Button>
          <Button style={{marginLeft:8}} color="inherit"><Link style={style} href="https://cults3d.com/en/users/balloondog/creations" underline="none">3D Models</Link></Button>
          <Button style={{marginLeft:8}} color="inherit"><Link style={weight} href="https://balloondog-store.nl" underline="none">Store</Link></Button>


            <IconButton style={{marginLeft:12,marginTop:8}} edge="end" color="inherit" aria-label="menu">
              <Link href="https://instagram.com/balloondogcaps" underline="none">
                <Instagram />
              </Link>
            </IconButton>

            <IconButton style={{marginTop:8}} edge="end" color="inherit" aria-label="menu">
              <Link href="https://discord.gg/bqN9rMdSXV" underline="none">
                <Discord />
              </Link>
            </IconButton>
          
      </>
    );
  }

  private listLinks(){
    const style = {width:"60vw", color:"black", marginTop:16}
    const styleNotable = {...style, color:theme.palette.primary.main, fontWeight:600, marginTop:110}
    return (

      <List style={{height:"calc(100vh - 16px)", margin:0, overflowY:"hidden"}}>
              <img style={{
                height:120,
                width:120,
                position:"absolute",
                left:-24,
                top:-24,
                transform: "rotate(110deg)"
              }} src="ditto.gif"/>
        <ListItem >
          <Link style={styleNotable} color="inherit" href="https://balloondog-store.nl" underline="none">Store</Link>
        </ListItem>
        <ListItem>
          <Link style={style}  href="https://dutchtypeworks.atlassian.net/servicedesk" underline="none">Create Ticket</Link>
        </ListItem>
        <ListItem>
          <Link style={style}  href="https://github.com/balloondogcaps" underline="none">Build Guides</Link>
        </ListItem>
        <ListItem>
          <Link style={style}  href="https://cults3d.com/en/users/balloondog/creations" underline="none">3D Models</Link>
        </ListItem>
        <ListItem divider={true}></ListItem>

        <ListItem>
            <IconButton style={{marginLeft:-12,marginTop:8}} edge="end" color="inherit" aria-label="menu">
              <Link href="https://instagram.com/balloondogcaps" underline="none">
                <Instagram />
              </Link>
            </IconButton>
            <IconButton style={{marginLeft:0,marginTop:8}} edge="end" color="inherit" aria-label="menu">
              <Link href="https://discord.gg/bqN9rMdSXV" underline="none">
                  <Discord />
              </Link>
            </IconButton>
        </ListItem>
      </List>
    )
  }

  private toggleDrawer(val: boolean){
    this.setState({open:val});
  }
}