import { Box } from "@mui/material";
import { Component } from "react";
import { style, theme } from "./Theme";

export class Footer extends Component {
    render() {
      return (
          <div style={{backgroundColor: theme.palette.primary.dark}}>
                <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                bgcolor="#333333"
                color="primary.contrastText"
                maxWidth={style.page.maxWidth}
                margin="0 auto"
                >
                    <p>Balloondog | a DutchTypeWorks company</p>
                    <p>Copyright 2021</p>
                </Box>
          </div>
      );
    }
  }