import { ThemeProvider } from '@emotion/react';
import { Box } from '@mui/material';
import React from 'react';
import './App.css';
import { FAQ } from './views/FAQ';
import { Footer } from './views/Footer';
import {Sitebar} from './views/Sitebar';
import { theme } from './views/Theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
          <Sitebar/>
          <Box marginTop={8} style={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}>
            <FAQ/>
          </Box>
          <Footer/>
      </div>
    </ThemeProvider>
  );
}

export default App;
