import { Box, Button, Grid, Link, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Component } from "react";
import { style } from "./Theme";

export class FAQ extends Component {

    render() {
        //@ts-ignore`
        const listItemStyle = { 
            fontWeight:600
        };
        return (
            <div style={{paddingLeft:16, paddingRight:16, maxWidth: style.page.maxWidth, margin: "0 auto" }}>
                <Typography variant="h4" gutterBottom align="center" marginBottom={4}>Frequently Asked Questions</Typography>
                <Grid container style={{ margin: "0 auto"}}>
                    <Grid item xs={12} sm={6}>
                        <List>
                        <ListItem>
                            <ListItemText
                                    primaryTypographyProps={{ style: listItemStyle }}
                                    primary="What is the status of a post-production product, raffle, or group buy?"
                                    secondary="You can check the status of these orders on our webshop. Each product is crafted with care by our team and shipped directly as soon as it is ready. We intentionally opt for a personal approach, shipping products individually instead of batching as this delivers the best results. If you have specific questions or concerns about your order, please do not hesitate to contact us using the form on the DutchTypeWorks support page."
                                />
                        </ListItem>
                            <ListItem>
                                <ListItemText
                                primaryTypographyProps={{ style: listItemStyle }}
                                primary="My package is in transit longer than I expected, what can I do?"
                                secondary="We use international postal services to ship our parcels. This means the parcels get handled by at least 2 parties. Every party has its guidelines and workflow which in turn can cause a delay in some situations. In our experience, the most delay occurs at the receiving country’s import stage. Our advice is to contact your national postal service or customs department."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                primaryTypographyProps={{ style: listItemStyle }}
                                primary="My tracking is not updating, what to do?"
                                secondary="When a tracking link is not updating there is a point in the transit chain that is not providing (all) information. In our experience that only happens at customs in the receiving country.
                                To get more information out of your tracking link you can use a website like https://parcelsapp.com/. Apps like ParcelsApp collect information from different sources and postal services."
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <List>
                            <ListItem>
                                <ListItemText 
                                    primaryTypographyProps={{ style: listItemStyle }}
                                    primary="When are products shipped?"
                                    secondary="PostNL collects parcels from us twice a week, on Mondays and Fridays. Stock products are shipped on one of these days. For post-production products, raffles, or group buys that are ready, we follow the same schedule, ensuring your items are on their way to you as soon as possible."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                primaryTypographyProps={{ style: listItemStyle }}
                                primary="Can I change the address of an order?"
                                secondary="Yes, please submit your request using the support button at the top of this page.
                                Please include the new address and your order number."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                primaryTypographyProps={{ style: listItemStyle }}
                                primary="Can I get more information about my parcel?"
                                secondary="Once a product is shipped and has left the country, all the information that we can access is the information provided by the tracking link. Please reach out to your national postal service for more information."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                primaryTypographyProps={{ style: listItemStyle }}
                                primary="My stem is a bit tight how can I fix this?"
                                secondary="Balloondog keycaps are tested on multiple switches before shipping but if you receive a keycap and the stem is too tight for your switch, please do not force the keycap on the stem. Let the keycap sit in warm water for about a minute, dry the cap, and carefully push it on the switch."
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Typography style={{ marginTop:40 }} variant="h4" gutterBottom align="center" marginBottom={4}>Question not answered?</Typography>
                <Typography variant="body1" gutterBottom align="center" marginBottom={4}>Feel free to create a support ticket and reach out to us using the button below. We will get back to you as soon as possible</Typography>
                <Box textAlign='center'>
                    <Button style={{ borderRadius: 50, marginBottom:40 }} variant="contained">
                        <Link style={{color:"white"}}  href="https://dutchtypeworks.atlassian.net/servicedesk/" underline="none">Create Ticket</Link>
                    </Button>
                </Box>
            </div>
        );
    }
  }
  