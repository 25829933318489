import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette:{
        primary: {
            main: "#c40282",
            dark:"#333333"
        }
    },
    typography: {
        fontSize: 16,
    },
    components:{
        MuiLink:{
            defaultProps:{

            }
        }
    }
});

export const style = {
    page: {
        maxWidth: 1024
    }
}